import React from 'react';
import styled from 'styled-components';
import "../../style/components/links/LinkItem.scss"

export interface LinkItemNewProps {
    readonly item: LinksItemProps;
}

export interface LinksItemProps {
    readonly title: string;
    readonly linkUrl: string;
    readonly imagePath: string;
    readonly hoverHexBackgroundColor: string;
    readonly hoverHexTextColor: string
}

export class LinkItemNew extends React.Component<LinkItemNewProps> {

    render() {

        const Link = styled.button`
          display: flex;
          width: 90%;
          margin: 6% 5%;
          align-items: center;
          height: fit-content;
          background: #21d4da;
          transition: all 1s;
          border: solid rgba(184, 184, 184, 0.398) 1px;

          text-align: center;
          box-shadow: 0px 0px 2px 0.5px #2c2c2cc9;
          bottom:0.5px;
          z-index:-1;
          
          &:hover {
            cursor: pointer;
            box-shadow: 1px 1px 25px 10px rgba(255,255,255,.1);
            background: ${this.props.item.hoverHexBackgroundColor};
    
            .link-text {
              color: ${this.props.item.hoverHexTextColor};
            }
          }
        `;

        return (
            <div className="link-wrap">
                <a href={this.props.item.linkUrl}>
                    <Link>
                        <div className="link-image-wrap">
                            <img src={this.props.item.imagePath} className="link-image" alt="Social Logo Icon"/>
                        </div>
                        <p className="link-text">{this.props.item.title}</p>
                    </Link>
                </a>
            </div>
        );
    }
}