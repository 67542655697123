import React from 'react';
import '../style/components/App.scss';
import LinksList from "./links/LinksList";

function App() {
  return (
      <div className="page">
          <div className="top-bar">
              <h1>Oli's Links</h1>
          </div>
          <div className="main-content">
              <div className="button-wrap">
                  <div className="button-views">
                      <LinksList />
                  </div>
              </div>
          </div>
          <div className="footer">
              <div className="cright">
                  <p>Copyright © 2021 | <a href="/">Oli Wheatley</a> | All Rights Reserved</p>
              </div>
          </div>
      </div>
  );
}

export default App;
