import React from 'react';
import {LinksItemProps} from "./LinkItem";
import firebase from "firebase";
import {LinkItemNew} from "./LinkItemNew";

export class LinksList extends React.Component<{}, {finalLinks: LinksItemProps[]}> {

    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.state = {
            finalLinks: []
        }
    }

    componentDidMount() {
        const fetchLinkData = async () => {
            const finalLinks: LinksItemProps[] = [];
            const links = await firebase.firestore().collection("links").get();

            links.forEach((querySnapshot) => {
                const newLink = querySnapshot.data();
                finalLinks.push({
                    title: newLink.title,
                    linkUrl: newLink.linkUrl,
                    imagePath: newLink.imagePath,
                    hoverHexBackgroundColor: newLink.hoverHexBackgroundColor,
                    hoverHexTextColor: newLink.hoverHexTextColor
                });
            });

            this.setState({finalLinks});
        }

        fetchLinkData();
    }

    render() {
        return <> {
            this.state.finalLinks.map((link: LinksItemProps) => {
                return (
                    <div key={link.title}>
                        <LinkItemNew item={link} />
                    </div> )
            })
        } </>
    }
}

export default LinksList;