import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';

firebase.initializeApp({
    apiKey: "AIzaSyBQEK_NZA9XLXABwEWPUFDVkRKSDl96_7k",
    authDomain: "oli-wheatley-link-site.firebaseapp.com",
    databaseURL: "https://oli-wheatley-link-site.firebaseio.com",
    projectId: "oli-wheatley-link-site",
    storageBucket: "oli-wheatley-link-site.appspot.com",
    messagingSenderId: "763040733810",
    appId: "1:763040733810:web:888aeb4ee6315987b300eb",
    measurementId: "G-KCPE9RZCJ1"
});

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
firebase.analytics();
